<template>
  <f7-page class="orderview-page">
    <template #fixed>
      <ProductNavigationComponent
        :title="$t.getTranslation('LBL_ORDER_INFO')"
        :type="navType"
        :search="false"
        :cart="false"
        :hidechat="true"
        :parentOrderKey="Order.ParentOrderKey"
        :orderKey="Order.OrderKey"
        :invoice="Order?.Payment?.IsSuccess ? true : false"
      />
      <OrderToolbarComponent :order="OrderKey" />
    </template>

    <div class="orderview-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.PaymentLink"
            name=""
            label="Input Payment Link"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            floating-label
            outline
            type="textarea"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-item>
            <f7-button fill @click="paynow()">Pay by Wechat</f7-button>
          </f7-list-item>
        </f7-list>

        <p v-if="formData?.Error">
          {{ formData.Error }}
        </p>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { configs, extendedConfigs } from "@/utils/configs";
import { defineComponent, ref, defineAsyncComponent, reactive } from "vue";
import { post, get } from "@/utils/axios";
import _ from "lodash";
import { f7 } from "framework7-vue";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";
import axios from "axios";

// import ProductNavigationComponent from '@/components/navigations/ProductNavigationComponent.vue'
// import OrderToolbarComponent from '@/components/navigations/OrderToolbarComponent.vue'
// import OrderRateServiceComponent from '@/components/OrderRateServiceComponent.vue'

const ProductNavigationComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"
  )
);
const OrderToolbarComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "order-toolbar" */ /* webpackMode: "lazy" */ "@/components/navigations/OrderToolbarComponent.vue"
  )
);
const OrderRateServiceComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "prder-rate-service" */ /* webpackMode: "lazy" */ "@/components/OrderRateServiceComponent.vue"
  )
);

export default defineComponent({
  name: "OrderTestPage",
  components: {
    OrderToolbarComponent,
    ProductNavigationComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $f7router = f7.views.get("#main-view").router;
    const Order = ref({});
    const formData = reactive({
      PaymentLink: "",
      Order: "",
      Error: null,
      Success: null,
    });
    const isProcesssing = ref(false);

    let checkWeixin = helpers.isInsideWeixin();
    let isMini = helpers.isMiniProgram();

    const paynow = async () => {
      alert("GET PAYMENT DATA NOW");

      helpers.showLoader("Getting information...");
      if (isProcesssing.value) {
        return true;
      }
      isProcesssing.value = true;
      if (!checkWeixin) {
        helpers.createNotification({
          type: "error",
          title: "LBL_ERROR",
          message: "Can use this only in inside wechat",
        });
        return;
      }
      if (formData?.PaymentLink) {
        //let ret = await get(formData.PaymentLink)
        try {
          alert("Check URL");

          let ret = await axios.get(formData.PaymentLink.trim()).catch((err) => {
            formData.Error(JSON.stringify(err));
          });
          let OrderKey = ret?.data?.Bale?.OrderKey || 0;

          isProcesssing.value = false;
          helpers.hideLoader();

          //move
          ret = ret?.data || null;

          if (!ret) {
            throw {
              Code: "LBL_ERROR",
              Message: "Order not exists",
            };
          }

          alert(JSON.stringify(ret));

          if (ret?.response?.data?.url) {
            window.location.href = ret.response.data.url;
          } else if (ret?.response?.data?.pay_data) {
            let response_data = ret.response.data.pay_data;
            let encodedString = encodeURIComponent(response_data);
            wx.miniProgram.navigateTo({
              url: "/pages/pjfwines/payment?order=" + OrderKey + "&obj=" + encodedString,
            });
          } else {
            console.log(ret);
            alert("NO CORRECT DATA");
          }

          return;
        } catch (error) {
          helpers.createNotification({
            type: "error",
            title: error?.code || "Error",
            message: error?.Message || error.toString(),
          });
          return;
        }
      }

      isProcesssing.value = false;
      helpers.createNotification({
        type: "error",
        title: "LBL_ERROR",
        message: "Payment link required",
      });
      return;
    };

    return {
      Order,
      formData,
      paynow,
    };
  },
});
</script>

<style scoped>
.container > li {
  list-style: none;
}
textarea {
  width: 100%;
}
button {
  width: 100%;
}
</style>
